<div class="network-container" *ngIf="mode === 'company'">
  <mat-sidenav-container class="remedia-sidenav-container " [ngClass]="{'h-100vh': mode !== 'assets'}"
                         (backdropClick)="close('backdrop')">
    <mat-sidenav-content>
      <mat-card class="table-card mb-2">
        <mat-toolbar class="table-tools" role="heading">
          <div fxFlex fxLayout fxLayoutAlign="start">
            <span class="fs1">Network Scan <span class="fw3 badge badge-primary" *ngIf="sortedData.length">
          {{sortedData.length}}</span></span>
          </div>
          <div fxFlex fxLayout fxLayoutAlign="end">
            <div fxLayout fxLayoutGap="15px" class="navigation-items">
              <form fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field class="search-tool fs1" floatLabel="never">
                  <input matInput name="valuesearch" id="NsFilter" [(ngModel)]="rmFilter" placeholder="Search"
                         autocomplete="off">
                  <button mat-button *ngIf="!rmFilter || rmFilter === ''" aria-label="Search" matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <button mat-button *ngIf="rmFilter" matSuffix mat-icon-button aria-label="Clear"
                          (click)="rmFilter='';">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <button class="ml-1" (click)="initRP()" mat-icon-button="">
                  <mat-icon>refresh</mat-icon>
                </button>
              </form>
            </div>
          </div>
        </mat-toolbar>
        <mat-card-content>
          <div class="overflow-auto" *ngIf="mode === 'company'">
            <mat-tab-group class="mt-2" [selectedIndex]="tabIndex" (selectedIndexChange)="getTab($event)">
              <mat-tab label="Network Scan">
                <table matSort *ngIf="tabIndex === 0" (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
              <thead>
                <tr class="mat-header-row">
                  <!-- <th class="mat-header-cell text-center" [ngClass]="{'w-20': currentAsset, 'w-40': !currentAsset}">
                    <mat-checkbox [(ngModel)]="selectAll" (change)="selectAllToggle()" class="pt-2"
                                  [checked]="selectAll && isAllSelected()"
                                  [indeterminate]="hasSelected() && !isAllSelected()" value="" color="primary">
                    </mat-checkbox>
                  </th> -->
                  <th mat-sort-header="vul_id" class="mat-header-cell text-center">CVE</th>
                  <th mat-sort-header="title" class="mat-header-cell">Title</th>
                  <th mat-sort-header="severity" class="mat-header-cell">Severity</th>
                  <th mat-sort-header="score" class="mat-header-cell">CVSS Score</th>
                  <th mat-sort-header="assets" class="mat-header-cell">Asset(s)</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of sortedData | search: rmFilter" class="mat-row">
                  <!-- <td class="mat-cell text-center">
                    <mat-checkbox [(ngModel)]="data.selected" value="" class="pt-2" color="primary"></mat-checkbox>
                  </td> -->
                  <td class="mat-cell">{{data.vul_id}}</td>
                  <td class="mat-cell"><span (click)="cs.copyClip(data.title)" [matTooltip]="data.title">{{data.title | ellipsis: 35}}</span></td>
                  <td class="mat-cell">
                    <span *ngIf="data.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
                    <span *ngIf="data.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
                    <span *ngIf="data.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
                    <span *ngIf="data.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
                    <span *ngIf="data.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
                  </td>
                  <td class="mat-cell">{{data.score}}</td>
                  <td class="mat-cell">
                    <span class="pointer pull-up bor-1 px-2" *ngIf="data.assets" (click)="showHosts(data)">
                      {{data.assets.length}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
              </mat-tab>
              <mat-tab label="Asset Level Network Scan">
                <table matSort *ngIf="tabIndex === 1" (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
                  <thead>
                    <tr class="mat-header-row">
                      <!-- <th class="mat-header-cell text-center" [ngClass]="{'w-20': currentAsset, 'w-40': !currentAsset}">
                        <mat-checkbox [(ngModel)]="selectAll" (change)="selectAllToggle()" class="pt-2"
                                      [checked]="selectAll && isAllSelected()"
                                      [indeterminate]="hasSelected() && !isAllSelected()" value="" color="primary">
                        </mat-checkbox>
                      </th> -->
                      <th mat-sort-header="asset_name" class="mat-header-cell text-center">Asset Name</th>
                      <th mat-sort-header="Critical" class="mat-header-cell">Critical</th>
                      <th mat-sort-header="High" class="mat-header-cell">High</th>
                      <th mat-sort-header="Medium" class="mat-header-cell">Medium</th>
                      <th mat-sort-header="Low" class="mat-header-cell">Low</th>
                      <th mat-sort-header="total" class="mat-header-cell">Vulnerabilities</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of sortedData | search: rmFilter" class="mat-row">
                      <!-- <td class="mat-cell text-center">
                        <mat-checkbox [(ngModel)]="data.selected" value="" class="pt-2" color="primary"></mat-checkbox>
                      </td> -->
                      <td class="mat-cell">{{data.asset_name}}</td>
                      <td class="mat-cell"><span class=" fw4 fs-i px-2 badge scritical" *ngIf="data.count">{{data.count.Critical}}</span>
                      <td class="mat-cell"><span class=" fw4 fs-i px-2 badge shigh" *ngIf="data.count">{{data.count.High}}</span>
                      <td class="mat-cell"><span class=" fw4 fs-i px-2 badge smedium" *ngIf="data.count">{{data.count.Medium}}</span>
                      <td class="mat-cell"><span class=" fw4 fs-i px-2 badge slow" *ngIf="data.count">{{data.count.Low}}</span>
                      <td class="mat-cell">
                        <span class="pointer pull-up bor-1 px-2" *ngIf="data.total" (click)="showVuls(data)">
                          {{data.total}}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
    <mat-sidenav #sidenav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p">
      <mat-card class="w-100" *ngIf="mode === 'company'">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="sidenav.close();">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw2 mr-4" *ngIf="currentNs && currentNs.assets && tabIndex === 0">Assets</mat-card-title>
          <mat-card-title class="fs1 fw2 mr-4" *ngIf="currentNs && currentNs.vulnerabilities && tabIndex === 1">Vulnerabilities</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflow-auto" *ngIf="currentNs && currentNs.assets && tabIndex === 0">
          <app-networkasset [solution]="currentNs" [currentCompany]="currentCompany"></app-networkasset>
        </mat-card-content>
        <mat-card-content class="overflow-auto" *ngIf="currentNs && currentNs.vulnerabilities && tabIndex === 1">
          <app-networkvuls [solution]="currentNs"></app-networkvuls>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
<div class="network-global-container" *ngIf="mode === 'global'">
  <mat-sidenav-container class="global-network-sidenav-container " [ngClass]="{'h-100vh': mode !== 'assets'}"
                         (backdropClick)="close('backdrop')">
    <mat-sidenav-content>
      <mat-card class="table-card mb-2">
        <mat-toolbar class="table-tools" role="heading">
          <div fxFlex fxLayout fxLayoutAlign="start">
            <span class="fs1">Network Scan
              <span class="fw3  badge badge-primary" *ngIf="GsortedData.length"> {{GsortedData.length}} </span>
            </span>
          </div>
          <div fxFlex fxLayout fxLayoutAlign="end">
            <div fxLayout fxLayoutGap="15px" class="navigation-items">
              <form fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field class="search-tool fs1" floatLabel="never">
                  <input matInput name="valuesearch" [(ngModel)]="rmFilter" placeholder="Search"
                         autocomplete="off">
                  <button mat-button *ngIf="!rmFilter || rmFilter === ''" aria-label="Search" matSuffix mat-icon-button>
                    <mat-icon>search</mat-icon>
                  </button>
                  <button mat-button *ngIf="rmFilter" matSuffix mat-icon-button aria-label="Clear"
                          (click)="rmFilter='';">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <button class="ml-1" (click)="initRP()" mat-icon-button="">
                  <mat-icon>refresh</mat-icon>
                </button>
              </form>
            </div>
          </div>
        </mat-toolbar>
        <mat-card-content>
          <div class="overflow-auto" *ngIf="mode === 'global'">
            <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
              <thead>
              <tr class="mat-header-row">
                <th mat-sort-header="vul_id" class="mat-header-cell text-center">CVE</th>
                <th mat-sort-header="title" class="mat-header-cell">Title</th>
                <th mat-sort-header="score" class="mat-header-cell">Severity</th>
                <th mat-sort-header="score" class="mat-header-cell">CVSS Score</th>
                <th mat-sort-header="company" class="mat-header-cell">Company(s)</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let gNScan of GsortedData | search: rmFilter" class="mat-row">
                  <td class="mat-cell"> {{gNScan.vul_id}}</td>
                    <td class="mat-cell">{{gNScan.title}}
                    </td>
                  <td class="mat-cell">
                    <span *ngIf="gNScan.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
                    <span *ngIf="gNScan.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
                    <span *ngIf="gNScan.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
                    <span *ngIf="gNScan.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
                    <span *ngIf="gNScan.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
                  </td>
                  <td class="mat-cell">{{gNScan.score}}</td>
                  <td class="mat-cell">
                    <span class="pointer pull-up bor-1 px-2" *ngIf="gNScan.companies" (click)="showCompanies(gNScan)">
                      {{gNScan.companies.length}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
    <mat-sidenav #sidenav [mode]="'over'" fixedTopGap="56" position="end" class="w-70p">
      <mat-card class="w-100" *ngIf="mode === 'global'">
        <button class="float-right r-1p mt--10" mat-icon-button aria-label="close modal icon" matTooltip="Close"
                (click)="sidenav.close();">
          <mat-icon>close</mat-icon>
        </button>
        <mat-card-header>
          <mat-card-title class="fs1 fw2 mr-4">Companies</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflow-auto" *ngIf="currentNs && currentNs.companies">
          <table matSort (matSortChange)="sortData($event)" class="crud-table mat-table w-100 ibtn bor-1">
            <thead>
              <tr class="mat-header-row">
                <th mat-sort-header="companyName" class="mat-header-cell">Company</th>
                <th mat-sort-header="title" class="mat-header-cell">Title</th>
                <th mat-sort-header="score" class="mat-header-cell">Severity</th>
                <th mat-sort-header="score" class="mat-header-cell">CVSS Score</th>
                <th mat-sort-header="assets" class="mat-header-cell">Asset(s)</th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngFor="let data of currentNs.companies|search:rmFilter; let i = index;">
                <tr class="mat-row">
                  <td class="mat-cell">
                    <div class="d-flex align-middle">
                      <span class="p-2">{{data.companyName}}</span>
                    </div>
                  </td>
                  <td class="mat-cell"><span (click)="cs.copyClip(data.title)" [matTooltip]="data.title">{{data.title | ellipsis: 35}}</span></td>
                  <td class="mat-cell">
                    <span *ngIf="data.severity === 'Critical'" class=" fw4  px-1 badge scritical">CRITICAL</span>
                    <span *ngIf="data.severity === 'High'" class=" fw4  px-1 badge shigh">HIGH</span>
                    <span *ngIf="data.severity === 'Medium'" class=" fw4  px-1 badge smedium">MEDIUM</span>
                    <span *ngIf="data.severity === 'Low'" class=" fw4  px-1 badge slow">LOW</span>
                    <span *ngIf="data.severity === 'Info'" class=" fw4  px-1 badge sinfo">INFO</span>
                  </td>
                  <td class="mat-cell">{{data.score.cvss_score}}</td>
                  <td class="mat-cell">
                    <span class="pointer pull-up bor-1 px-2" *ngIf="data.assets" (click)="data.visible = true; showAssets(data, i)">
                      {{data.assets.length}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="data.visible">
                  <td colspan="5">
                    <mat-card>
                      <button class="float-right mt--10" mat-icon-button aria-label="close modal icon"
                              matTooltip="Close" (click)="data.visible = !data.visible">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-card-header>
                        <mat-card-title class="fs1 fw2">Asset Details</mat-card-title>
                      </mat-card-header>
                      <mat-card-content class="overflow-auto max-height-200">
                        <app-networkasset *ngIf="currentNs.companies[i].currentGNs && currentNs.companies[i].currentGNs.assets" [solution]="currentNs.companies[i].currentGNs"
                        [currentCompany]="currentCompany" [companyId]="currentNs.companies[i].companyId"></app-networkasset>

                      </mat-card-content>
                    </mat-card>
                  </td>
                </tr>
              </ng-container>
              </tbody>
            </table>
        </mat-card-content>
      </mat-card>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
<app-integration-action [inputActionData]="actionData" *ngIf="integrationActionService.integrationActionShow">
</app-integration-action>

