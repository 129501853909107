import { Component, OnInit, Input } from '@angular/core';
import { CompanySharedService } from '../../_services/company-shared.service';
import { LoaderService } from '../../_services/loader.service';
import { MyToastrService } from '../../_services/toastr.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { CompanyService } from '../../api/services/company.service';
import { BaseRequestService } from 'src/app/_services/base.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonService } from 'src/app/_services/common.services';

@Component({
  selector: 'app-discovery-settings',
  templateUrl: './discovery-settings.component.html',
  styleUrls: ['./discovery-settings.component.scss']
})
export class DiscoverySettingsComponent {
  @Input() currentCompany: any;
  @Input() currentAgent: any;
  bruteForcePorts: any = [];
  bruteForceEnabled = false;
  scanTypeList = ['', 'Full Scan', 'Asset Scan', 'Vulnerability Scan', 'External Scan', 'Port Scan'];
  scanType: any;
  st = '';
  notes: any = 'This tab allows you to allocate the required number of processes to be executed at a time, when a scan is triggered.\n' +
    '\n' +
    'This is based on the system configuration where the agent is installed.\n' +
    '\n' +
    '(Ex - 4 Core Processor - 16 Processes)';
  maxThreadCount = null;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  removable = true;
  selectable = true;

  constructor(private cs: CompanySharedService, private baseService: BaseRequestService,
    public commonService: CommonService,
    private confirmDialog: ConfirmDialogService, private companyService: CompanyService,
    private loaderService: LoaderService, private toast: MyToastrService) {
    cs.companyScanEVE.subscribe((value: any) => {
      this.st = value.st;
      this.scanType = this.scanTypeList[value.st];
      this.confirmScan();
    });
  }

  updateProcessCount(): void {
    this.loaderService.display(true);
    this.baseService.doRequest(`/api/agent/`,
      'put', { _id: this.currentAgent?._id, maxThreadCount: this.maxThreadCount }).subscribe((res: any) => {
        this.loaderService.display(false);
        if (res) {
          this.currentAgent = { ...this.currentAgent, ...{ maxThreadCount: this.maxThreadCount } };
          this.maxThreadCount = res?.maxThreadCount;
          this.toast.sToast('success', 'Count Updated Sucessfully!');
        } else {
          this.toast.sToast('error', res);
        }
      });

  }
  ngOnInit(): void {
    this.bruteForceEnabled = this.currentAgent.bruteForceEnabled;
    if (this.currentAgent.bruteForcePorts && this.currentAgent.bruteForcePorts.length) {
      this.bruteForcePorts = [];
      this.currentAgent.bruteForcePorts.forEach((item: any, index: number) => {
        this.bruteForcePorts[index] = { name: item };
      });
    }
    setTimeout(() => {
      this.maxThreadCount = (this.currentAgent.maxThreadCount === undefined) ? '' : this.currentAgent.maxThreadCount;
    }, 500);
  }

  confirmScan(): void {
    this.loaderService.display(true, `Initiating a ${this.scanType}`);
    this.companyService.companyscanApiCompanyIdScanPost({
      id: this.currentCompany._id, body: {
        companyid: this.currentCompany._id,
        scantype: +this.st
      }
    }).subscribe(result => {
      this.loaderService.display(false);
      if (result[0]) {
        this.toast.sToast('success', result[1]);
      } else {
        this.toast.sToast('success', result[1]);
      }
    });
  }
  addPort(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.bruteForcePorts.push({ name: value.trim() });
    }
    if (input) {
      input.value = '';
    }
  }
  removePort(port: any): void {
    const index = this.bruteForcePorts.indexOf(port);
    if (index >= 0) {
      this.bruteForcePorts.splice(index, 1);
    }
  }

  updateBruteForcePorts(): void {
    this.loaderService.display(true);
    let reqData: any = {
      _id: this.currentAgent?._id,
      bruteForceEnabled: this.bruteForceEnabled,
    };
    if (this.bruteForceEnabled && this.bruteForcePorts && this.bruteForcePorts.length) {
      reqData.bruteForcePorts = this.bruteForcePorts.map((x: any) => x.name);
    } else {
      reqData.bruteForcePorts = [];
      this.bruteForcePorts = [];
    }
    this.currentAgent = { ...this.currentAgent, ...reqData };
    this.baseService.doRequest(`/api/agent/`, 'put', reqData).subscribe((res: any) => {
      this.loaderService.display(false);
      if (res) {
        this.toast.sToast('success', 'Brute Force Ports Updated Sucessfully!');
      } else {
        this.toast.sToast('error', res);
      }
    });
  }
}
